app-tokens-dashboard {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.sidebar-open {
  .wrap-main .sidebar {
    display: block;
  }
}

/* .navbar:not(.home-background):not(.fixed-bottom) {
  flex: 0 0 auto;
  box-shadow: none;
  height: 64px;
  padding: 0;
  &.bg-primary {
    background-image: linear-gradient(to bottom, #1b72b8, #095592);
  }
  .container-fluid {
    padding-right: 24px;
    padding-left: 24px;
  }
  .navbar-brand{
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 0rem;
    font-weight: bold;
    font-size: 20px;
    line-height: inherit;
    white-space: nowrap;
  }
  .justify-content-end {
    margin-left: auto;
    flex: 0 0 auto;
  }
  .navbar-nav {
    flex-direction: row !important;
    .nav-link {
      // padding-right: 0.5em;
      padding-right: 0em;
      padding-left: 0.5em;
    }
  }
} */

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 18px;
  margin: auto 20px;
  margin-left: 0px;
  @include breakpoint(phablet) {
    margin: auto 5px;
  }
  cursor: pointer;
  .hamburger-inner {
    position: absolute;
    width: 24px;
    height: 1px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    background-color: #fff;
    top: 0;
    &:before, &:after {
      position: absolute;
      width: 24px;
      height: 1px;
      transition-timing-function: ease;
      transition-duration: .15s;
      transition-property: transform;
      background-color: #fff;
      display: block;
      content: "";
    }
    &:before {
      top: 8px;
      width: 16px;
    }
    &:after {
      top: 16px;
    }
  }
}

.wrap-main {
  flex: 1 1 auto;
  display: flex;
  height: calc(100% - 64px);
  .sidebar {
    flex: 0 0 auto;
    height: 100%;
    overflow: auto;
    display: none;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(30, 35, 37, 0.08);
    width: 220px;
    @include breakpoint(tablet) {
      position: fixed;
      top: 64px;
      left: 0;
      bottom: 0;
      z-index: 9999;
      height: auto;
    }
  }
  .main {
    flex: 1 1 auto;
    overflow: auto;
  }
  .page-wrapper {
    max-width: 1100px; //1000px;
    padding: 48px 20px;
    // padding: 48px 20px 0px 20px;
  }
}




.main-navigation {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 0 auto;
  &__category {
    border-bottom: 1px solid #f4f6f8;
    padding-bottom: 16px;
    order: 2;
    &:last-child {
      border-bottom: 0;
    }
    &.active {
      order: 1;
      .main-navigation__pin {
        color: $nice-blue;
      }
    }
  }
  &__header {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $battleship-grey;
  }
  &__pin {
    color: $battleship-grey;
    cursor: pointer;
  }
  .nav__link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    color: $dark-grey;
    &.active {
      color: $nice-blue;
      background: #f4f6f8;
    }
  }
  .nav__text {
    flex: 1 1 auto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  }
  .nav__group {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    color: $dark-grey;
    cursor: pointer;
    &.active {
      color: $nice-blue;
      background: #f4f6f8;
    }
    &:not([href]):not([tabindex]) {
      color: $dark-grey;
    }
  }
  .nav__children {
    background: #f5f5f5;
  }
  .nav__icon {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 8px;
    i {
      font-size: 20px;
      display: block;
      height: 20px;
      width: 20px;
      line-height: 20px;
    }
  }
  .nav__collapsable {
    color: $dark-grey;
    height: 24px;
  }
}

.footer-navigation {
  margin-top: auto;
  flex: 0 0 auto;
  .nav__link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    color: $dark-grey;
    &.active {
      color: $nice-blue;
      background: #f4f6f8;
    }
  }
  .nav__text {
    flex: 1 1 auto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  }
  .nav__icon {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 8px;
    i {
      font-size: 20px;
      display: block;
      height: 20px;
      width: 20px;
      line-height: 20px;
    }
  }
}

.page-header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
  &__left {
    flex: 1 1 auto;
  }
  &__right {
    flex: 0 0 auto;
  }
}

.page-title {
  h2 {
    font-size: 28px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: $dark-grey;
    margin: 0;
  }
}

.page-description {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: $battleship-grey;
  margin-top: 16px;
  margin-bottom: 50px;
}

.page-breadcrumbs {
  margin-bottom: 16px;
  @include breakpoint(tablet) {
    display: none;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    li {
      &:after {
        content: ">";
        margin: 0 10px;
        color: $battleship-grey;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
      }
      &:last-child {
        &:after {
          display: none;
        }
        span {
          color: $dark-grey;
          cursor: default;
        }
      }
      span {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: $battleship-grey;
        cursor: pointer;
      }
    }
  }
}





.crud {
  position: relative;
  $crud: &;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    &__left {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
    }
    &__buttons {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      > * {
        margin-right: 5px;
      }
    }
    &__right {
      flex: 0 0 auto;
    }
  }
  &__table {
    &__container {
      &--visible {
        @include breakpoint(tablet) {
          display: block !important;
        }
      }
      @include breakpoint(tablet) {
        display: none;
      }
      .mat-table {
        overflow: auto;
      }
      &--frame {
        padding: 25px;
        background: #fff;
        box-shadow: 0 4px 8px 0 rgba(30, 35, 37, 0.08);
        border-radius: 2px;
      }
      .mat-header-row, .mat-row {
        min-height: 40px;
        padding: 0;
        border-bottom-color: #f7f7f7;
      }
      .mat-header-cell, .mat-cell {
        padding-right: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        display: block;
        font-size: 14px;
        color: $battleship-grey;
      }
      .mat-row.row-disabled {
        .mat-cell {
          color: $battleship-grey;
        }
      }
      .mat-cell {
        color: $dark-grey;
      }
      .column-link {
        color: $nice-blue;
        cursor: pointer;
      }
    }
    &__mobile {
      &__container {
        background: #fff;
        display: none;
        @include breakpoint(tablet) {
          display: block;
        }
      }
      &__header {
        border-bottom: 1px solid #f2f2f2;
        padding: 8px 16px;
      }
      &__item {
        border-bottom: 1px solid #f2f2f2;
        $item: &;
        &--frame {
          #{ $item }__content {
            padding-left: 0;
            padding-right: 0;
          }
          #{ $item }__buttons {
            margin: 0;
          }
        }
        &__content {
          display: flex;
          padding-top: 20px;
          padding-left: 16px;
          padding-right: 16px;
          outline: none;
          &__left {
            flex: 0 0 auto;
            min-width: 40px;
            outline: none;
          }
          &__right {
            flex: 1 1 auto;
            outline: none;
          }
        }
        &__name {
          font-size: 20px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.2;
          letter-spacing: normal;
          color: $dark-grey;
          margin-bottom: 8px;
        }
        &__fields {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -10px;
          padding-bottom: 10px;
          > div {
            width: 50%;
            padding: 10px;
            padding-bottom: 0;
          }
          &__label {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: $battleship-grey;
          }
          &__value {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: $dark-grey;     
            word-break: break-all;        
            word-wrap: break-word; /* Internet Explorer 5.5+ */            
            white-space: pre-wrap; /* css-3 */       
          }
        }
        &__description {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: $battleship-grey;
          word-break: break-all;        
          word-wrap: break-word; /* Internet Explorer 5.5+ */            
          white-space: pre-wrap; /* css-3 */
        }
        &__buttons {
          border-top: 1px solid #f7f7f7;
          margin: 0 16px;
          padding-left: 40px;
          padding-top: 8px;
          padding-bottom: 8px;
          display: flex;
          justify-content: space-between;
          &__left {
            flex: 1 1 auto;
          }
          &__right {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            margin-left: auto;
          }
        }
        &__action {
          margin-left: 25px;
          span {
            cursor: pointer;
            color: $nice-blue;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
          }
          &--red {
            span {
              color: $rust-red;
            }
          }
        }
      }
    }
  }
  &__loading {
    padding: 36px 0;
    text-align: center;
    background: rgba(255,255,255, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 65px;
    bottom: 0;
    .mat-progress-spinner {
      display: inline-block;
    }
  }
  .column-status {
    color: #fff;
    border-radius: 13px;
    display: inline-block;
    padding: 6px 17px;
    line-height: 1;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__left{
      display: flex;
      align-items: center;
    }
    &__right {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
    &__action {
      margin-left: 8px;
      display: none;
      span {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid $dark-grey;
        color: $dark-grey;
        line-height: 24px;
        outline: none;
        box-shadow: none;
        background: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        label {
          display: none;
          color: $nice-blue;
        }
        &:hover {
          background: $dark-grey;
          color: #fff;
        }
        .mat-icon {
          width: 16px;
          height: 16px;
          font-size: 16px;
          line-height: 16px;
        }
      }
      &--red {
        span {
          border: 1px solid $rust-red;
          color: $rust-red;
          label {
            color: $rust-red;
          }
          &:hover {
            background: $rust-red;
            color: #fff;
          }
        }
      }
    }
  }
  .mat-row {
    &:hover {
      background: rgba(245, 245, 245, 0.4);
      #{ $crud }__buttons__action {
        display: flex;
      }
    }
  }
}




.modal-content {
  border-radius: 0;
  border: none;
  background-color:#F6F6F6;
}

.selectable-options {
  display: flex;
  align-items: center;
  overflow: hidden;
  .selectable-option {
    flex: 0 0 auto;
    margin-right: -1px;
    &:nth-child(1){
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label
      {
        border-radius: 2px 0 0 2px;
      }
    }
    &:nth-child(2){
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label
      {
        border-radius: 0 2px 2px 0;
      }
    }
    &:nth-child(3){
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label
      {
        border-radius: 0 2px 2px 0;
      }
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
      display: none;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
      border: 1px solid $nice-blue;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: $dark-grey;
      text-align: center;
      min-width: 140px;
      padding: 10px 15px;
      @include breakpoint(phablet) {
        min-width: 90px;
        padding: 10px 5px;
      }
      margin: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    [type="radio"]:checked + label:before {
      content: '';
      display: inline-block;
      width: 9px;
      height: 18px;
      border: solid $apple-green;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
      margin-top: -8px;
      margin-right: 13px;
    }
    [type="radio"]:not(:checked) + label:before {

    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {

    }
    [type="radio"]:checked + label {
      background: $nice-blue;
      color: #fff;
    }
  }
}

.bulk-checkbox {
  label {
    margin: 0;
  }
  .mat-checkbox-ripple {
    display: none;
  }
  &.mat-checkbox-checked, &.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: $nice-blue !important;
    }
  }
  .mat-checkbox-frame {
    border-color: $nice-blue-grey;
    border-radius: 0;
  }
}


.small-window .modal-dialog {
  max-width: 369px;
}

.big-window .modal-dialog {
  max-width: 572px;
}

.form-checkbox {
  display: flex;
  margin-bottom: 16px;
  &__left {
    flex: 0 0 auto;
    margin-right: 10px;
  }
  &__right {
    flex: 1 1 auto;
    padding-top: 10px;
  }
  &__label {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $dark-grey;
    margin-bottom: 8px;
    display: block;
  }
  &__description {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 17px;
    letter-spacing: normal;
    color: $battleship-grey;
    display: block;
  }
}

.mobile-window {
  .modal-dialog {
    max-width: 300px;
  }
}

.modal-dialog {
  margin: 1.75rem auto !important;
}
