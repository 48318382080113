@use "@angular/material" as mat;
@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "assets/scss/core";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
$gold-palette: (
  50: #f2eada,
  100: #e1cba4,
  200: #ccaa6a,
  300: #b98a2d,
  400: #ae7400,
  500: #a36000,
  600: #a15700,
  700: #9d4a00,
  800: #973b00,
  900: #8e2000,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$project-o-primary: mat.define-palette($gold-palette, 200);
$project-o-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$project-o-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$project-o-theme: mat.define-light-theme($project-o-primary,
    $project-o-accent,
    $project-o-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($project-o-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: Albert Sans, sans-serif;
  font-weight: normal;
  background: #f4f4f4 !important;
}

@import "./app/components/dashboard/sandbox/table-responsive.directive.scss";