@mixin ellipsis(
  $width: 100%,
  $display: inline-block
) {
  display: $display;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
  @include pseudo($pos: $position);
  width: 0;
  height: 0;
  @if $round {
    border-radius: 3px;
  }
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round( $size / 2.5 );
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round( $size / 2.5 );
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if  $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}


@mixin breakpoint($point) {
  @if $point == laptop {
    @media (max-width: 1200px) {
      @content;
    }
  }
  @else if $point == laptoponly {
    @media (max-width: 1200px) and (min-width: 992px) {
      @content;
    }
  }
  @else if $point == tablet {
    @media (max-width: 992px) {
      @content;
    }
  }
  @else if $point == tabletonly {
    @media (max-width: 992px) and (min-width: 768px) {
      @content;
    }
  }
  @else if $point == phablet {
    @media (max-width: 768px) {
      @content;
    }
  }
  @else if $point == phabletonly {
    @media (max-width: 768px) and (min-width: 576px) {
      @content;
    }
  }
  @else if $point == mobile {
    @media (max-width: 576px) {
      @content;
    }
  } @else {
    @media (max-width: $point) {
      @content;
    }
  }
}
