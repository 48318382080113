$dark-grey: #1e2325;
$battleship-grey: #6e7581;
$nice-blue: #0e67ae;
$nice-blue-grey: #becad4;
$light-blue-grey: #becad4;
$rust-red: #b80c09;
$macaroni-and-cheese: #e0ca3c;
$apple-green: #a5ea2c;
$apple-green-dark: #85c227;

.btn {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  // border: 1px solid transparent;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 0px;
  color: #fff;
  height: 40px;
  background-image: linear-gradient(to bottom, #1b88de, $nice-blue);
  padding: 10px 20px;
  box-shadow: 0 4px 8px 0 rgba(30, 35, 37, 0.08);
  &:hover {
    color: #fff;
    background-image: linear-gradient(to bottom, rgba(#1b88de, 0.6), rgba($nice-blue, 0.6));
  }
  &:disabled, &--disabled {
    background: $light-blue-grey;
  }
  &--transparent {
    background: transparent;
    color: $nice-blue;
    box-shadow: none;
    &:hover {
      color: #1b88de;
      background: transparent;
    }
  }
  &--secondary {
    background: #fff;
    color: $dark-grey;
    border-color: $nice-blue;
    &:hover {
      border-color: rgba($nice-blue, 0.6);
      background: #fff;
      color: $dark-grey;
    }
  }
  &--warn {
    border-color: $rust-red;
    background: $rust-red;
    color: #fff;
    &:hover {
      background-image: linear-gradient(to bottom, rgba(#E90F0A, 0.6), rgba($rust-red, 0.6));
      color: #fff;
    }
  }
  &--green {
    border: 0;
    color: #fff;
    background-image: linear-gradient(to bottom, #bbe34f, #7bb71f);
    &:hover {
      color: #fff;
      background-image: linear-gradient(to bottom, rgba(#bbe34f, 0.6), rgba(#7bb71f, 0.6));
    }
  }
  &--black {
    border: 0;
    color: #fff;
    background: black;
    &:hover {
      color: #fff;
      background: black;
    }
  }
  &--yellow{
    border: 0;
    color: black;
    background: #cca96a;
    &:hover {
      color: black;
      background: #cca96a;
    }
  }
}

.form-group {
  margin-bottom: 22px;
  label {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $dark-grey;
    margin-bottom: 4px;
  }
  .form-control {
    border-radius: 2px;
    border: 1px solid $light-blue-grey;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $dark-grey;
    height: auto;
    box-shadow: none !important;
    padding: 9px 16px;
    &:hover, &:focus {
      box-shadow: none !important;
      border-color: $nice-blue;
    }
    &:disabled {
      border: 1px solid $light-blue-grey;
      background: rgba($dark-grey, 0.08);
      color: $battleship-grey;
    }
  }
  .multiselect-dropdown .dropdown-btn {
    border-radius: 2px !important;
    border: 1px solid $light-blue-grey !important;
    box-shadow: none !important;
    padding: 5px 16px 0 16px !important;
    min-height: 40px;
    position: relative !important;
    padding-right: 35px !important;
    &:hover, &:focus {
      box-shadow: none !important;
      border-color: $nice-blue !important;
    }
    > span:first-child {
      display: none;
    }
    // +1 znak u dropdownmenu
    > span:last-child {
      color: #0E67AE;
    }
    .dropdown-down {
      position: absolute !important;
      right: 15px !important;
      top: 14px !important;
      display: block !important;
      border: none !important;
      width: 15px !important;
      height: 15px !important;
      color: $dark-grey !important;
      font-size: 28px !important;
      font-weight: 100 !important;
      line-height: 10px !important;
      outline: none !important;
      &:after {
        content: ">";
        transform: rotate(90deg)scale(0.8, 1.3);
        top: 0 !important;
        position: absolute !important;
        display: block !important;
        border: none !important;
        width: 15px !important;
        height: 15px !important;
        transform-origin: 67% 50%;
      }
    }
    .dropdown-up {
      position: absolute !important;
      right: 15px !important;
      top: 14px !important;
      display: block !important;
      border: none !important;
      width: 15px !important;
      height: 15px !important;
      color: $dark-grey !important;
      font-size: 28px !important;
      font-weight: 100 !important;
      line-height: 10px !important;
      outline: none !important;
      &:after {
        content: ">";
        transform: rotate(-90deg)scale(0.8, 1.3);
        top: 0 !important;
        position: absolute !important;
        display: block !important;
        border: none !important;
        width: 15px !important;
        height: 15px !important;
        transform-origin: 50% 33%;
      }
    }
    .selected-item {
      font-size: 14px !important;
      font-weight: normal !important;
      font-style: normal !important;
      font-stretch: normal !important;
      letter-spacing: normal !important;
      color: $dark-grey !important;
      padding: 3px 8px !important;
      background-color: $light-blue-grey !important;
      display: flex !important;
      border-radius: 2px !important;
      border: none !important;
      align-items: center !important;
      margin-right: 6px !important;
      margin-bottom: 5px !important;
      box-shadow: none !important;
      &:hover {
        box-shadow: none !important;
      }
      a {
        color: $dark-grey !important;
        flex: 0 0 auto !important;
        cursor: pointer !important;
        font-size: 28px !important;
        font-weight: 100 !important;
        width: 15px !important;
        height: 15px !important;
        line-height: 10px !important;
        outline: none !important;
        user-select: none !important;
        transform: scale(1, 0.75);
        padding: 0 !important;
        margin-left: 10px;
      }
    }
  }
  .ng-select .ng-select-container {
    border-radius: 2px !important;
    border: 1px solid $light-blue-grey !important;
    box-shadow: none !important;
    min-height: 40px;
    position: relative !important;
    height: auto;
    padding: 0 !important;
    .ng-value-container {
      padding-left: 0;
    }
    .ng-value {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: $dark-grey;
      position: static;
      padding: 9px 16px !important;
      padding-right: 35px !important;
    }
    &:hover, &:focus {
      box-shadow: none !important;
      border-color: $nice-blue !important;
    }
  }
  .ng-dropdown-panel.ng-select-bottom {
    margin-top: 10px;
    background: #fff !important;
    border-radius: 2px !important;
    box-shadow: 0 4px 8px 0 rgba(30, 35, 37, 0.08) !important;
    font-size: 14px;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: #000;
    font-weight: 400;
  }
  .ng-select .ng-clear-wrapper {
    display: none !important;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items {
    margin-bottom: 0;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background: rgba(245, 245, 245, 0.4);
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background: rgba(245, 245, 245, 0.6);
  }
  .ng-select.ng-select-opened>.ng-select-container .ng-arrow-wrapper {
    position: absolute !important;
    right: 15px !important;
    top: 14px !important;
    display: block !important;
    border: none !important;
    width: 15px !important;
    height: 15px !important;
    color: $dark-grey !important;
    font-size: 28px !important;
    font-weight: 100 !important;
    line-height: 10px !important;
    outline: none !important;
    .ng-arrow {
      display: none !important;
    }
    &:after {
      content: ">";
      transform: rotate(-90deg)scale(0.8, 1.3);
      top: 0 !important;
      position: absolute !important;
      display: block !important;
      border: none !important;
      width: 15px !important;
      height: 15px !important;
      transform-origin: 50% 33%;
    }
  }
  .ng-select .ng-arrow-wrapper {
    position: absolute !important;
    right: 15px !important;
    top: 14px !important;
    display: block !important;
    border: none !important;
    width: 15px !important;
    height: 15px !important;
    color: $dark-grey !important;
    font-size: 28px !important;
    font-weight: 100 !important;
    line-height: 10px !important;
    outline: none !important;
    &:after {
      content: ">";
      transform: rotate(90deg)scale(0.8, 1.3);
      top: 0 !important;
      position: absolute !important;
      display: block !important;
      border: none !important;
      width: 15px !important;
      height: 15px !important;
      transform-origin: 67% 50%;
    }
    .ng-arrow {
      display: none !important;
    }
  }
}

.dropdown-list {
  background: #fff !important;
  border-radius: 2px !important;
  box-shadow: 0 4px 8px 0 rgba(30, 35, 37, 0.08) !important;
  font-size: 14px;
  .no-data h5 {
    font-size: 14px !important;
    font-weight: normal !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 1.43 !important;
    letter-spacing: normal !important;
    color: $rust-red !important;
    margin: 10px 0;
  }
}

